import React, { useState, useEffect, useReducer, useMemo } from "react";
import ReactPaginate from "react-paginate";
import { Row, Col, Button, Card, Spinner } from "react-bootstrap";
import { debounce } from "lodash";
import { useTranslation } from "react-i18next";

import request from "services/request";
import CustomTable from "components/customTable";
import SortFilter from "components/customTable/sortFilter";
import { cloneDeep } from "common/utils.ts";
import { useLoading } from "contexts/LoadingContextManagement";
import asd from './Vector.png'
import {
  DEBOUNCE_DELAY,
  DEFAULT_ERROR_MESSAGE,
  INGREDIENT_CATEGORIES,
  ITEMS_PER_PAGE,
} from "common/constants";
import { useUserData } from "contexts/AuthContextManagement";

import CustomModal from "./modal";
import DeleteModal from "views/commonViews/DeleteModal";
import reducer, { ACTION_TYPES, initialState } from "./reducer";
import { getAlternativeIngredients, getCarbonReducerMealDummyData, getCarbonMealDummyData, getProvidersDummy, ingredientsKeys, providerIngredientsKeys, requiredIngredientsKeys, requiredProviderIngredientsKeys, getReducerMealsDetialsData } from "../../settings/utils";

import Addicon from "assets/images/icon/ADD_WHITE.svg";
import Trashicon from "assets/images/icon/filter.svg";
import EyeIcon from "assets/images/icon/eye.svg";
import SWITCH from "assets/images/switch.png";
import Water_DROP from "assets/images/water_drop.png";
import LAND_INDEX from "assets/images/land_index.png";

import "./index.scss";
import PriceHistory from "./PriceHistory";
import CustomProviderModal from "./providerModal";
import ImportModule from "../../settings/common/ImportIngredients";
import ProgressItem from "../analytics/progressItem";
import { toast } from "react-toastify";
import storage from "services/storage";

const IMPACT_INGREDIENTS = [
  {
    name: "Viande",
    value: 85,
  },
  {
    name: "Pâte",
    value: 53,
  },
  {
    name: "Lait",
    value: 2,
  },
  {
    name: "Fromage",
    value: 5,
  },
]

const IMPACT_ITEMS = {
    climate_change_agriculture: "Agriculture",
    climate_change_processing: "Transformation",
    climate_change_packaging: "Shipping",
    climate_change_transport: "Transport",
    climate_change_distribution: "distribution",
    climate_change_consumption: "Consumption",
  }

function MyIngredients() {
  const { t } = useTranslation();
  const { setLoading, setError } = useLoading();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [showDetails, setShowDetails] = useState(false)
  const [modalShow, setModalShow] = useState(false);
  const [importModalShow, setImportModalShow] = useState(false);
  const [fd, setfd] = useState({ orderBy: "desc", search: "" });
  const [selectedIngredient, setSelectedIngredient] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [deleteModalShow, setDeleteModelShow] = useState(false);
  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState([]);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [isMyIngredients, setIsMyIngredients] = useState(true);
  const [options, setOptions] = useState(ingredientsKeys);
  const [requiredOptions, setRequiredOptions] = useState(requiredIngredientsKeys);
  const [allergens, setAllergens] = useState(null)
  const [alternativeIngredients, setAlternativesIngredients] = useState([])
  const [currentReducerMeal, setCurrentReducerMeal] = useState(null)
  const [suggestedIngredients, setSuggestedIngredients] = useState(null)
  const [isAlternativeLoading ,setIsAlternativeLoading] = useState(false)
  const [sleaveState, setSleaveState] = useState({
    index: -1,
    isOpen: true,
  });
  const is_demo = JSON.parse(storage.getItem("is_demo"))

  let meal_total_co2 = 0
  if(currentReducerMeal) {
    meal_total_co2 = currentReducerMeal.carbon_footprint_ingredients.reduce((acc, item) => acc + (item.CarbonFootprintIngredientMeal?.ingredient_quantity * item.climate_change), 0)
  }

  const {
    selectedRestaurantId,
    isRestaurantLoaded,
    hasRetaurants,
    setSampleRestaurantModal,
  } = useUserData();

  let IMPACT_VALUE_KEYS = currentReducerMeal && Object.keys(currentReducerMeal?.life_cycle_impact)

  useEffect(() => {
    if (isMyIngredients) {
      setOptions(ingredientsKeys);
      setRequiredOptions(requiredIngredientsKeys);
    } else {
      setOptions(providerIngredientsKeys);
      setRequiredOptions(requiredProviderIngredientsKeys);
    }
  }, [isMyIngredients]);

  const myIngredientsTableColumns = [
    {
      dataField: "name",
      caption: t("Meals"),
      className: "fw-bold",
      style: { height: "auto", whiteSpace: "normal" },
      headerStyle: { width: "150px" },
      type: "string",
    },
    // // {
    // //   dataField: "reference_name",
    // //   caption: t("ReferenceName"),
    // //   className: "text-center",
    // //   headerClassName: "text-center",
    // //   type: "string",
    // // },
    // {
    //   dataField: "format",
    //   caption: t("Format"),
    //   className: "text-center",
    //   headerClassName: "text-center",
    //   type: "string",
    // },
    // {
    //   dataField: "unit_price",
    //   caption: t("Unit price"),
    //   className: "text-center",
    //   headerClassName: "text-center",
    //   type: "number",
    // },
    // {
    //   dataField: "provider_name",
    //   caption: t("Provider"),
    //   className: "text-center",
    //   headerClassName: "text-center",
    //   type: "string",
    // },
    // {
    //   dataField: "category",
    //   caption: t("Impact carbone"),
    //   className: "text-center",
    //   headerClassName: "text-center",
    //   type: "customRender",
    //   render: (_, it) => (
    //     <span>
    //       {t(
    //         INGREDIENT_CATEGORIES.find(({ id }) => id === it?.category)?.label
    //       )}
    //     </span>
    //   ),
    // },
    // {
    //   dataField: "unit",
    //   caption: t("Unit"),
    //   className: "text-center",
    //   headerClassName: "text-center",
    //   type: "customRender",
    //   render: (_, it) => {
    //    return <span>
    //       {t(it?.unit)}
    //     </span>
    //   }
    // },
    {
      // dataField: "cost_excl_tax",
      caption: t("Carbon impact (in Kg CO2eq)"),
      className: "text-center",
      headerClassName: "text-center",
      type: "customRender",
      render: (_, it) => {
        return <span>{it.carbon_footprint_ingredients?.reduce((acc, item) => acc + ((item.CarbonFootprintIngredientMeal?.ingredient_unit === 'kg' ? item.CarbonFootprintIngredientMeal?.ingredient_quantity : item.CarbonFootprintIngredientMeal?.ingredient_quantity / 1000) * item.climate_change), 0)?.toFixed(4)}</span>
      }
    },
    {
      dataField: "action",
      caption: "",
      className: "text-center",
      headerClassName: "text-center",
      type: "customRender",
      render: (col, it) => (
        <img
          src={EyeIcon}
          className="cursor-pointer"
          alt="..."
          onClick={showRow(it)}
        />
      ),
    },
  ];

  const providerIngredientsTableColumns = [
    {
      dataField: "name",
      caption: t("Name"),
      className: "fw-bold",
      style: { width: "150px" },
      headerStyle: { width: "150px" },
      type: "string",
    },
    {
      dataField: "provider_name",
      caption: t("Provider"),
      className: "text-center",
      headerClassName: "text-center",
      type: "string",
    },
    {
      dataField: "conditioning_name",
      caption: t("ConditioningName"),
      className: "text-center",
      headerClassName: "text-center",
      type: "number",
    },
    {
      dataField: "price_excl_tax",
      caption: t("Price (without tax)"),
      className: "text-center",
      headerClassName: "text-center",
      type: "string",
    },
    {
      dataField: "recipe_unit",
      caption: t("RecipeUnit"),
      className: "text-center",
      headerClassName: "text-center",
      type: "string",
    },
    {
      dataField: "recipe_unit_quantity",
      caption: t("RecipeUnitQuantity"),
      className: "text-center",
      headerClassName: "text-center",
      type: "string",
    },
    {
      dataField: "action",
      caption: "",
      className: "text-center",
      headerClassName: "text-center",
      type: "customRender",
      render: (col, it) => (
        <img
          src={EyeIcon}
          className="cursor-pointer"
          alt="..."
          onClick={showRow(it)}
        />
      ),
    },
  ];

useEffect(() => {
  if(suggestedIngredients) {
    setAlternativesIngredients(suggestedIngredients.filter(item => item.alternative_ingredient))
  }
},[suggestedIngredients])
  // Invoke when user click to request another page.
  const handlePageClick = async (event) => {
    try {
      let ingredients;
      let result
      if(isMyIngredients){
        result = await getIngredients(event.selected + 1);
        ingredients = result.cf_meals
      }else {
        result = await getProviderIngredients(event.selected + 1)
        ingredients = mapIngredientsProvider(result.provider_ingredients)
      }
      setCurrentItems(() => ingredients);
      setPageCount(result.total_pages);
      setCurrentPage(event.selected);
    } catch (error) {
      console.log(error);
      setError(DEFAULT_ERROR_MESSAGE);
    }
  };


  const updateRecipe = async() => {
    if(is_demo) {
      toast.success(t("Recipe updated successfully"))
      setSuggestedIngredients(suggestedIngredients.map(item => ({...item, alternative_ingredient: item.original_ingredient, original_ingredient: item.alternative_ingredient})))
      return
    }

    if(alternativeIngredients.length) {
      // Update obj1.data based on matching id values with arr elements
     const updatedIngredients = currentReducerMeal.carbon_footprint_ingredients.map(ing => {
        const matchedItem = alternativeIngredients.find(alterIng => alterIng.original_ingredient === ing.name);
        if (matchedItem) {
          return { 
            ingredient_id: matchedItem.alternative_id,
            ingredient_unit: "kg",
            ingredient_quantity: ing.CarbonFootprintIngredientMeal.ingredient_quantity,
            ingredient_cost: null,
            ingredient_co2_impact: matchedItem.alternative_co2_impact_per_kg,
            ingredient_is_local: false,
            converted_unit: null,
            converted_quantity: null
          }
        }
        return {
          ingredient_id: ing.id,
          ingredient_unit: ing?.CarbonFootprintIngredientMeal?.ingredient_unit,
          ingredient_quantity: ing.CarbonFootprintIngredientMeal.ingredient_quantity,
          ingredient_cost: null,
          ingredient_co2_impact: ing?.climate_change,
          ingredient_is_local: false,
          converted_unit: ing?.CarbonFootprintIngredientMeal?.ingredient_unit,
          converted_quantity: ing.CarbonFootprintIngredientMeal.ingredient_quantity
        }
      })
      if(updatedIngredients?.length) {
        setIsAlternativeLoading(true)
        const result = await request.patch(`/carbon-footprint-meals/${currentReducerMeal?.id}`, {name: currentReducerMeal?.name, servings: currentReducerMeal?.servings, restaurant_id: currentReducerMeal?.restaurant_id ,carbon_footprint_ingredients : updatedIngredients})
        if(result.ok) {
          let fdIngredients = cloneDeep(currentReducerMeal.carbon_footprint_ingredients);
          let foundIndex = currentReducerMeal.carbon_footprint_ingredients.findIndex(item => item.name === alternativeIngredients[0].original_ingredient)
          let updatedSuggestedIngredients = cloneDeep(suggestedIngredients)
          updatedSuggestedIngredients[foundIndex] = {...updatedSuggestedIngredients[foundIndex], alternative_ingredient: updatedSuggestedIngredients[foundIndex].original_ingredient, original_ingredient: updatedSuggestedIngredients[foundIndex].alternative_ingredient}
          setSuggestedIngredients(updatedSuggestedIngredients)
          let ingredient = {
            CarbonFootprintIngredientMeal: {
              ingredient_unit: "kg",
              ingredient_quantity: alternativeIngredients[0].ingredient_quantity,
              ingredient_cost: null,
              ingredient_co2_impact: alternativeIngredients[0].alternative_co2_impact_per_kg,
              ingredient_is_local: false,
              converted_unit: "kg",
              converted_quantity: alternativeIngredients[0].ingredient_quantity
            },
            id: alternativeIngredients[0].alternative_id,
            category: null,
            climate_change: alternativeIngredients[0].alternative_co2_impact_per_kg,
            cost_excl_tax: null,
            format: alternativeIngredients[0].ingredient_quantity,
            name: alternativeIngredients[0].alternative_ingredient,
            unit: "kg"
          }
          fdIngredients[foundIndex] = ingredient
          setCurrentReducerMeal(prev => ({...prev, carbon_footprint_ingredients: fdIngredients}))
          toast.success(t("Recipe updated successfully"))
          setAlternativesIngredients([])
          setIsAlternativeLoading(false)
        }
      }
    }
  }

  useEffect(() => {
    if(selectedRestaurantId) {
      getInitialData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRestaurantId, isRestaurantLoaded]);

  let ingredients = []
  let reducerMeals = []

  const loadAlternativeIngredients =async () => {
    // Filter array A to get records that exist in B
    // const result = alternativeIngredients.filter(itemA => selectedIngredient?.id === itemA.id);
    // setSuggestedIngredients(result)
    if(isRestaurantLoaded && !hasRetaurants && is_demo) {
      const alternatives = getAlternativeIngredients()
      let asd = alternatives.filter(item => item.id === currentReducerMeal?.item_id)
      // setAlternativesIngredients(asd.slice(0, currentReducerMeal?.carbon_footprint_ingredients?.length))
      // setSuggestedIngredients(asd.slice(0, currentReducerMeal?.carbon_footprint_ingredients?.length));
      return
    }

    if(selectedIngredient?.carbon_footprint_ingredients?.length > 0) {
      let maxCEObject = selectedIngredient?.carbon_footprint_ingredients?.reduce((max, current) => {
        return (current.climate_change > max.climate_change) ? current : max;
    }, selectedIngredient?.carbon_footprint_ingredients[0]);
      let requestIngredient = `${maxCEObject?.name}:${maxCEObject.climate_change}`
      if(maxCEObject) {
        try {
          setIsAlternativeLoading(true)
          let lowerIngredientResponse = await request.get("carbon-footprints/ingredients/get-recommended-alternatives", {ingredients: requestIngredient}, true, false);
          const foundIndex = suggestedIngredients.findIndex(item => item.original_ingredient === maxCEObject.name)
          if(foundIndex === -1) {
            toast.error(t(`No Alternative is found for `) + `${maxCEObject?.name}`);
            setIsAlternativeLoading(false)
          }
          let fdIngredients = cloneDeep(suggestedIngredients);
          fdIngredients[foundIndex] = {original_ingredient: maxCEObject.name, alternative_id: lowerIngredientResponse?.alternatives[0]?.alternative_id, original_co2_impact_per_kg: maxCEObject?.climate_change, alternative_ingredient: lowerIngredientResponse?.alternatives[0]?.alternative_ingredient, alternative_co2_impact_per_kg: lowerIngredientResponse?.alternatives[0]?.alternative_co2_impact_per_kg}
          setSuggestedIngredients(fdIngredients);
          // setSuggestedIngredients(lowerIngredientResponse?.alternatives?.map(i => ({...i, ingredient_quantity: maxCEObject.CarbonFootprintIngredientMeal?.ingredient_quantity})));
          setIsAlternativeLoading(false)
        }catch(error) {
          console.log({error})
          setIsAlternativeLoading(false)
          toast.error(t(`No Alternative is found for `) + `${maxCEObject?.name}`);
        }
      }
    }
  }

  useEffect(() => {
    if (isRestaurantLoaded && !hasRetaurants) {
      if(isMyIngredients) {
        ingredients = getCarbonMealDummyData()?.cf_meals
        reducerMeals = getCarbonReducerMealDummyData()
      }
    }
  }, [selectedIngredient])

  useEffect(() => {
      fetchAndSetIngredients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fd.search, fd.sortby, fd.orderBy, selectedRestaurantId, isMyIngredients]);
  const fetchAndSetIngredients = async () => {
    try {
      
      let result = {};
      if (isRestaurantLoaded && !hasRetaurants && is_demo) {
          ingredients = getCarbonMealDummyData()
        if(fd.search) {
          ingredients = ingredients?.cf_meals?.filter(item => (item.name.toLowerCase().includes(fd.search.toLowerCase())))
        }
        if(fd.sortby) {
          ingredients = ingredients?.cf_meals?.sort((a, b) => (a[fd.sortby] > b[fd.sortby]) ? 1: -1)
        }
        result = {
          ingredients,
          total_pages: 1,
          page: 0,
        };
        setCurrentItems(ingredients?.cf_meals);
        setPageCount(result.total_pages);
        setCurrentPage(result.page);
        setLoading(false);
        return
      }
      if(isMyIngredients) {
        result = await getIngredients();
        ingredients = result.cf_meals;
        dispatch({ type: ACTION_TYPES.INGREDIENTS, ingredients });
      } else {
        result = await getProviderIngredients();
        ingredients = mapIngredientsProvider(result.provider_ingredients);
        dispatch({ type: ACTION_TYPES.PROVIDER_INGREDEINTS, ingredients });
      }
      setCurrentItems(() => ingredients);
      setPageCount(result.total_pages);
      setCurrentPage(result.page - 1);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setError(DEFAULT_ERROR_MESSAGE);
    }
  };

  const getIngredients = (page = 1) => {
    if(selectedRestaurantId) {
      const payload = {
        restaurant_id: selectedRestaurantId,
        limit: ITEMS_PER_PAGE,
        page,
        order_by: fd.orderBy.toUpperCase(),
        ...(fd.sortby && { sort_by: fd.sortby }),
        ...(fd.search && { search: fd.search }),
      };
      return request.get(`carbon-footprint-meals`, payload);
    }
    return {
      cf_meals: [],
      total_pages: 1,
      page: 0,}
  };

  const getProviderIngredients = (page = 1) => {
    const payload = {
      restaurant_id: selectedRestaurantId,
      limit: ITEMS_PER_PAGE,
      page,
      order_by: fd.orderBy.toUpperCase(),
      ...(fd.sortby && { sort_by: fd.sortby }),
      ...(fd.search && { search: fd.search }),
    };
    return request.get(`provider-ingredients`, payload);
  };

  const getAllergens = () => request.get("allergens");

  const getProviders = () => {
    if (isRestaurantLoaded && !hasRetaurants) {
      return getProvidersDummy();
    }
    return request.get(`providers?restaurant_id=${selectedRestaurantId}`);
  };

  const mapIngredientsProvider = (ingredients) => {
    return ingredients.map(({ provider, ...ingredient }) => ({
      provider_name: provider?.name,
      provider_id: provider?.id,
      ...ingredient,
    }));
  };

  const getInitialData = async () => {
    try {
      setLoading(true);
      const [allergens, providers] = await Promise.all([
        getAllergens(),
        getProviders(),
      ]);

      setAllergens(allergens.allergens)

      dispatch({
        type: ACTION_TYPES.MULTI,
        payload: {
          allergens: allergens.allergens,
          providers: providers.providers,
        },
      });
      setLoading(false);
    } catch (error) {
      setError(DEFAULT_ERROR_MESSAGE);
    }
  };

  const showRow = (row) => async() => {
    if(isRestaurantLoaded && !hasRetaurants) {
      const selectedMeal = getReducerMealsDetialsData()?.co2eq_reducer?.find(item => item.id === row?.id)
      setSelectedIngredient(selectedMeal);
      setCurrentReducerMeal(selectedMeal);
      let suggestedIng = selectedMeal?.carbon_footprint_ingredients.map(item => ({
        original_ingredient: item?.name,
        original_co2_impact_per_kg: parseFloat(item?.climate_change/item.format),
        alternative_id: null,
        alternative_ingredient: null,
        alternative_cost_excl_tax: null,
        alternative_co2_impact_per_kg: null
      }))
      setSuggestedIngredients(suggestedIng)
      // setModalShow(true);
      setShowDetails(true);
      return
    }
    const selectedMeal = await request.get("carbon-footprints/meals/get-co2-reducer-details", {cf_meal_id: row?.id})
    setSelectedIngredient(selectedMeal?.co2eq_reducer);
    let suggestedIng = selectedMeal?.co2eq_reducer?.carbon_footprint_ingredients.map(item => ({
      original_ingredient: item?.name,
      original_co2_impact_per_kg: parseFloat(item?.climate_change/item.format),
      alternative_id: null,
      alternative_ingredient: null,
      alternative_cost_excl_tax: null,
      alternative_co2_impact_per_kg: null
    }))
    setSuggestedIngredients(suggestedIng)
    setCurrentReducerMeal(selectedMeal?.co2eq_reducer)

    // setModalShow(true);
    setShowDetails(true);
  };

  const onSearchChange = debounce((ev) => {
    setfd({ ...fd, search: ev.target.value });
  }, DEBOUNCE_DELAY);

  const onModalHide = () => {
    setModalShow(false);
    setSelectedIngredient(null)
  };

  const selectChange =
    (it) =>
    ({ target: { checked } }) => {
      const newSelectedRows = cloneDeep(selectedRows);
      if (checked) {
        setSelectedRows(() => [...newSelectedRows, it.id]);
      } else {
        setSelectedRows(() => newSelectedRows.filter((p) => p !== it.id));
      }
    };

  const selectAllRows = ({ target: { checked } }) => {
    setSelectedRows(() => (checked ? [...currentItems.map((i) => i.id)] : []));
  };

  const deleteRows = async () => {
    try {
      let path = isMyIngredients ? `/carbon-footprint-meals` : `/provider-ingredients`
      let optionsObj = isMyIngredients ? {cf_meal_ids: selectedRows } : {provider_ingredient_ids: selectedRows}
      const result = await request.delete(path, optionsObj);
      if (result.status === 200) {
        setSelectedRows([]);
        setCurrentItems(() =>
          currentItems.filter((i) => !selectedRows.includes(i.id))
        );
      }
    } catch (error) {
      console.log(error);
      setError(DEFAULT_ERROR_MESSAGE);
    }
  };

  const onRowAdded = () => {
    fetchAndSetIngredients();
    setSelectedIngredient(null);
  };

  const handleDoubleClick = (index, isOpen) => {
    if(isOpen) {
      setSleaveState(p => ({...p, isOpen: false}))
      setTimeout(() => {
        setSleaveState(p => ({...p, index: -1}))
      }, 300)
    } else {
      setSleaveState(p => ({...p, index, isOpen: true }))
    }
  }

  let processedCurrentItems = useMemo(() => {
    const newCurrentItems = [...currentItems];
    if(sleaveState.index !== -1) {
      // newCurrentItems.splice(sleaveState.index+1, 0, {prediction:{}, isOpen: sleaveState.isOpen})
      newCurrentItems[sleaveState.index] = {...newCurrentItems[sleaveState.index], prediction: { isOpen: sleaveState.isOpen }};
    }
    return newCurrentItems;
  }, [currentItems, sleaveState])

  if(sleaveState.index === -1 && isRestaurantLoaded && !hasRetaurants) {
    processedCurrentItems = currentItems
  }

  const modalTitle = isMyIngredients ? 'AddMultipleIngredients' : 'AddMultipleProviderIngredients'
  const requestPath = isMyIngredients ? '/ingredients/create-in-bulk' : '/provider-ingredients/create-in-bulk'
  const modalName = isMyIngredients ? 'Ingredient' : 'ProviderIngredient'

  return (
    <div className="my-ingredients">
      {isMyIngredients ? <CustomModal
        show={modalShow}
        onHide={onModalHide}
        onRowAdded={onRowAdded}
        data={{ ...state, ...(selectedIngredient && { selectedIngredient }) }}
      /> :
      <CustomProviderModal 
      show={modalShow}
      onHide={onModalHide}
      onRowAdded={onRowAdded}
      data={{ ...state, ...(selectedIngredient && { selectedIngredient }) }} 
      />}
      
      <ImportModule allergens={allergens} show={importModalShow} modalName={modalName} requestPath={requestPath} options={options} requiredOptions={requiredOptions} title={modalTitle} onHide={setImportModalShow} providers={state.providers} onRowAdded={onRowAdded} />
      <DeleteModal
        show={deleteModalShow}
        onHide={() => setDeleteModelShow(false)}
        onPositiveClicked={() => {
          setDeleteModelShow(false);
          deleteRows();
        }}
        modalData={{
          title: t(`Delete ${(modalName).toLocaleLowerCase()}s`),
          description: t(
            `Are you sure you want to delete ${(modalName).toLocaleLowerCase()}s ? You cannot undo this action.`
          ),
          positiveBtnTitle: t(`Yes, delete ${(modalName).toLocaleLowerCase()}s`),
        }}
      />
      {/* <Row>
        <Col lg={{ span: 3 }}>
          <p className="title-text">{t("Ingredients")}</p>
          <p className="sub-title-text">{t("MyIngredientList")}</p>
        </Col>
      </Row> */}
      <Row>
        <div className="d-flex row" style={{marginTop: "50px"}}>
          <Col style={{ width: "max-content", paddingLeft: "2%" }}>
            {showDetails ? <h2 className="title-text">{selectedIngredient?.name}</h2> : <h2>{t("General Stats of your Carbon Footprint")}</h2>}
            <p className="sub-title-text">
              {t("ReduceCarbonImpactByAI")}
            </p>
          </Col>

          <Col className="sort-container d-flex justify-content-end align-items-center gap-3">
          {showDetails && <Col style={{ textAlign: "end"}}>
              <Button type="submit" variant="primary save-button" style={{
                  width:"100px",
                  height:"auto"
                }}
              onClick={() => {
                setShowDetails(false)
                setCurrentReducerMeal(null)
              }}
                >
                {t("Back")}
              </Button>
            </Col>}

            {Boolean(selectedRows.length) && (
              <img
                src={Trashicon}
                className="me-3"
                onClick={() => {
                  if (isRestaurantLoaded && !hasRetaurants) {
                    setSampleRestaurantModal(true);
                    return;
                  }
                  setDeleteModelShow(true);
                }}
                alt="..."
              />
            )}
            <input
              type="search"
              style={{ width: "300px", height: "auto"}}
              className="search-input"
              placeholder={t("SearchDish")}
              onChange={onSearchChange}
            />
          </Col>
        </div>
      </Row>
      {(!showDetails && !currentReducerMeal) ? <Row className="mt-3 table-container">
      {isMyIngredients && (
        <CustomTable
          columns={myIngredientsTableColumns}
          data={processedCurrentItems}
          selectChange={selectChange}
          selectedProducts={selectedRows}
          selectAllProducts={selectAllRows}
        />
        )}
         {!isMyIngredients && (
           <CustomTable
           columns={providerIngredientsTableColumns}
           data={processedCurrentItems}
           selectChange={selectChange}
           selectedProducts={selectedRows}
           selectAllProducts={selectAllRows}
           onRowDoubleClick={handleDoubleClick}
           SleaveContent={PriceHistory}
         />
        )}
        <Row>
          <div className="d-flex justify-content-end mt-2">
            <ReactPaginate
              nextLabel={`${t("Next")}   >`}
              forcePage={currentPage}
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={pageCount}
              previousLabel={`<   ${t("Back")}`}
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination"
              activeClassName="active pagination-page-active"
              renderOnZeroPageCount={null}
            />
          </div>
        </Row>
      </Row> :
        <Row className="co2-reducer-details" style={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", marginTop: "40px" }}>
          <div style={{ display: "flex", flexDirection: "column", gap: "30px" }}>
            <Card className="reducer-impact-card" style={{ width: "80%", padding: "40px 20px", display: "flex", justifyContent: "center", alignItems: "center", margin: "auto", borderRadius: "10px" }}>
              <img src={Water_DROP} />
              <h2 style={{ fontSize: "31px", color: "#7D97F4", fontWeight: "400", fontSize: "31px" }}>{t("WaterVolume")}</h2>
              <p style={{ fontSize: "73px", color: "#7D97F4", fontWeight: "700", marginTop: "40px", marginBottom: "20px" }}>{currentReducerMeal?.water_volume?.toFixed(2) || 0}</p>
            </Card>
            <Card className="reducer-impact-card" style={{ width: "80%", padding: "40px 20px", display: "flex", justifyContent: "center", alignItems: "center", margin: "auto", borderRadius: "10px" }}>
              <img src={LAND_INDEX} />
              <h2 style={{ fontSize: "31px", color: "#FFC700", fontWeight: "400", fontSize: "31px" }}>{t("LandUseIndex")}</h2>
              <p style={{ fontSize: "73px", color: "#FFC700", fontWeight: "700", marginTop: "40px", marginBottom: "20px" }}>{currentReducerMeal?.land_use?.toFixed(2) || 0}</p>
            </Card>
            <Card className="reducer-impact-card" style={{ width: "80%", padding: "40px 20px", display: "flex", justifyContent: "center", alignItems: "center", margin: "auto", borderRadius: "10px" }}>
              <h2 style={{ fontSize: "31px", color: "#6353ea", fontWeight: "400", fontSize: "31px" }}>DQR</h2>
              <p style={{ fontSize: "55px", color: "#6353ea", fontWeight: "700", marginTop: "40px", marginBottom: "20px" }}>{currentReducerMeal?.dqr?.toFixed(2) || 0}</p>
            </Card>
            <Card  className="reducer-impact-card" style={{ width: "80%", padding: "40px 20px", display: "flex", justifyContent: "center", alignItems: "center", margin: "auto", borderRadius: "10px" }}>
              <h2 style={{ fontSize: "31px", color: "#6353ea", fontWeight: "400", fontSize: "31px" }}>PEF</h2>
              <p style={{ fontSize: "55px", color: "#6353ea", fontWeight: "800", marginTop: "40px", marginBottom: "20px"}}>{currentReducerMeal?.ef_score?.toFixed(2) || 0}</p>
            </Card>
            {/* 
            <Card className="reducer-impact-card" style={{ width: "80%", padding: "40px", margin: "auto", borderRadius: "10px"}}>
              <p style={{ fontSize: "20px", color: "#94a3b8"}}>
              {t("Product Environmental Footprint (PEF) measures the environmental impact of a product from raw material extraction  to disposal. It quantifies impacts such as resource use and emissions, enabling informed, sustainable decisions. The lower the PEF value, the less environmental impact the product has.")}
              </p>
            </Card> */}
            {/* <Card className="reducer-impact-card" style={{ width: "80%", padding: "40px", margin: "auto", borderRadius: "10px"}}>
              <h2 style={{ fontSize: "35px", fontWeight: "800", color: "#6353ea", textAlign: "center"}}>DQR : {currentReducerMeal?.dqr?.toFixed(2) || 0}</h2>
            </Card>
            <Card style={{ width: "80%", padding: "40px", margin: "auto", borderRadius: "10px", marginBottom: "20px"}}>
              <p style={{ fontSize: "20px", color: "#94a3b8"}}>
              {t("The Data Quality Ratio (DQR) is a scale from 1 to 5 used to rate the reliability and accuracy of data. A DQR close to 1 indicates high reliability, while a value close to 5 suggests significant uncertainty and lower reliability.")}
              </p>
            </Card> */}
          </div>
          <div className="reducer-impact">
            <Card className="reducer-impact-card" style={{ width: "100%", padding: "40px", height: "auto", borderRadius: "20px", position:"relative", zIndex:1}}>
              <span style={{ fontSize: "24px", fontWeight: "800", marginBottom: "30px"}}>{t("ImpactBy")} <span style={{color: "#6353ea"}}>{t("lifeCycleStage")}</span></span>
              <div style={{ display: "flex", flexDirection: "column"}}>
               {IMPACT_VALUE_KEYS?.map(key => (<ProgressItem style={{marginBottom: "30px", fonntSize: "40px"}} name={IMPACT_ITEMS[key]} value={(((currentReducerMeal?.life_cycle_impact?.[key] / meal_total_co2) * 100) || 0)?.toFixed(1)} />))} 
              </div>
            </Card>
            {/* <div
            className="reducer-impact-card"
            style={{
              width: "80%",
              height:"auto",
              backgroundColor:"#6353EA",
              color:"white",
              fontSize:"21px",
              top:"70%",
              left:"-10%",
              transform:"translate(0%, -54%)",
              zIndex:0,
              paddingTop:"44px",
              textAlign:"center",
              borderRadius:"20px",
              cursor: "pointer"
            }}
            onClick={loadAlternativeIngredients}
            >
              <p style={{paddingTop: "53px", paddingBottom: "14px"}}>{t("Load low carbon ingredients")}</p>
            </div> */}
          </div>
          <div className="reducer-impact">
            <Card  className="reducer-impact-card" style={{ width: "80%", padding: "40px 0px", margin: "auto", height: "auto", borderRadius: "10px", position:"relative", zIndex:1}}>
              <span style={{ fontSize: "24px", fontWeight: "800", marginBottom: "30px", textAlign: "center"}}>{t("Alternative-1 ")} <span style={{color: "#6353ea"}}>{t("recipe-1")}</span></span>
              {isAlternativeLoading ? 
              <div style={{width: "100%", height: "100px", display: "flex", justifyContent: "center", alignItems: "center"}}>
                <Spinner animation="border" variant="primary" />
              </div> : 
              // suggestedIngredients?.map((item, index) => (<div key={index} style={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)" ,backgroundColor: index%2 == 0 ? "#f3f4fb": "white", height: "auto", padding: "10px", fontSize: "16px", fontWeight: "700"}}><span style={{width: "100%", color: "black", fontSize: "12px"}}>{t(item?.original_ingredient)}</span> <span style={{width: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>{item?.alternative_ingredient &&<img onClick={updateRecipe} style={{cursor: "pointer"}} src={SWITCH} alt="switch" />}</span> <span style={{width: "100%", color: "rgb(99, 83, 234)", fontSize: "12px", textAlign: "end"}}>{t(item?.alternative_ingredient)}</span>   {item?.original_co2_impact_per_kg > 80 && <span><img src={asd} alt="asd" /></span>}
              // </div>))}
                suggestedIngredients?.map((item, index) => (
                  <div key={index} 
                      style={{
                          display: "grid",
                          gridTemplateColumns: "repeat(3, 1fr)",
                          backgroundColor: index % 2 === 0 ? "#f3f4fb" : "white",
                          height: "auto",
                          padding: "10px",
                          fontSize: "16px",
                          fontWeight: "700"
                      }}
                  >
                      <span style={{ width: "100%", color: "black", fontSize: "12px" }}>
                          {t(item?.original_ingredient)}
                      </span>
                      <span style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        {item?.alternative_ingredient && (
                          <img
                            onClick={updateRecipe}
                            style={{ cursor: "pointer" }}
                            src={SWITCH}
                            alt="switch"
                          />
                        )}
                      </span>
                      <div style={{ position: "relative", width: "100%" }}>
                      <span 
                        style={{ 
                          width: "100%", 
                          background: item?.alternative_ingredient ? "rgb(99, 83, 234)" : "transparent",
                          color: item?.alternative_ingredient ? "white" : "black",
                          padding: item?.alternative_ingredient ? "11px 6px" : "0px",
                          borderRadius:"10px", 
                          fontSize: "12px", 
                          textAlign: "start",
                          display:'block',
                          position: "relative",
                          zIndex: '1'
                        }}
                      >{t(item?.alternative_ingredient)}</span>
                       {item?.alternative_ingredient && (
                        <>
                          <div style={{
                              // background: 'white',
                              position: "absolute",
                              top: "-3px",
                              right: "-7px",
                              cursor: "pointer",
                              height: "35px",
                              width:  '38px',
                              zIndex: "2",
                              padding: '7px',
                              display:'flex',
                              alignItems: 'center',
                              borderTopRightRadius: '10px',
                              borderTopLeftRadius: '10px'
                            }}
                            onMouseEnter={(e) => {
                              e.currentTarget.style.backgroundColor = 'white';
                              e.currentTarget.querySelector('div').style.color = 'white'; 
                              e.currentTarget.querySelector('div').style.border = '2px solid #6353EA';
                              e.currentTarget.nextSibling.style.display = 'block';
                            }}
                            onMouseLeave={(e) => {
                              e.currentTarget.style.backgroundColor = ''; 
                              e.currentTarget.querySelector('div').style.color = item?.alternative_ingredient ? 'white' : '#6353EA';
                              e.currentTarget.querySelector('div').style.border = item?.alternative_ingredient ? '2px solid white' : '2px solid #6353EA';
                              e.currentTarget.nextSibling.style.display = 'none';
                            }}
                          >
                            <div
                              style={{
                                display: 'inline-block',
                                width: '25px',
                                height: '25px',
                                borderRadius: '50%',
                                border: item?.alternative_ingredient ? '2px solid white' : '2px solid #6353EA', 
                                backgroundColor: item?.alternative_ingredient ? '#6353EA' : 'transparent', 
                                color: item?.alternative_ingredient ? 'white' : '#6353EA', 
                                textAlign: 'center',
                                lineHeight: '25px', 
                                fontSize: '20px',
                                fontWeight: 'bold',
                                cursor: 'pointer',
                                transition: 'color 0.3s ease, border-color 0.3s ease', // Smooth transition for color change
                              }}
                            >
                              i
                            </div>
                          </div>
                          <div style={{
                            display: 'none',
                            position: "absolute",
                            top: "28px",
                            right: "0",
                            background: "white",
                            padding: "12px",
                            borderRadius: "12px",
                            boxShadow: "0 0 5px rgba(0, 0, 0, 0.2)",
                            zIndex: 1,
                            width: "260px",
                            borderTopRightRadius: '0px',
                            color: "#6353EA",
                            fontSize: "13px",
                          }}>
                              {t("FULLSOON suggests replacing this ingredient with one that has a lower carbon footprint.")}
                          </div>
                        </>
                      )}
                      {item?.original_co2_impact_per_kg > 80 && (<span><img src={asd} alt="asd" /></span>)}
                      </div>
                  </div>
                ))}            
            </Card>
            <div
              className="ingredient-card-button"
              style={{
                width: "79%",
                height:"auto",
                backgroundColor:"#6353EA",
                color:"white",
                fontSize:"21px",
                top:"70%",
                left:"-10%",
                transform:"translate(12%, -54%)",
                zIndex:0,
                paddingTop:"44px",
                textAlign:"center",
                borderRadius:"20px",
                cursor: "pointer",
                marginLeft:"3px"
              }}
              onClick={loadAlternativeIngredients}
            >
              <p style={{paddingTop: "53px", paddingBottom: "14px"}}>{t("Recommended lower carbon ingredients")}</p>
            </div>
        </div>
      </Row>}
    </div>
  );
}

export default MyIngredients;
